import React from 'react';
import './Aboutus.css'; // Import your CSS file for styling

const AboutUsPage = () => {
  // Define your dynamic content using state or props
  const aboutContent = 'Welcome to our About Us page! We are a dedicated team of developers building amazing web applications with React.';

  return (
    <div className="about-us">
      <h1>About Us</h1>
      <p>{aboutContent}</p>
      <div>
      <h2 className='h2'>UBRIKK PLATFORM</h2>
      <p>
					A next generation platform that connect vendor with cumstomers.<br></br> Here the products
					are goods are designed online and delivered at customer specs.<br></br> Our unique
					algorithem is delivered such that a user can design his perfective goods online with
					WYSIWYG interface.<br></br>This also enables the vendor manufacture the custom product as
					per client specs.<br></br>Hence one part of this platform is user is buyers, while the
					other is vendors.<br></br>We have to built the core MVP.Our current focus is to integrate
					first vendor type to our application.
				</p>
        <h3>SOCIO ECONOMIC IMPACT OF THE UBRIKK</h3>
      
        <p>
					
						<li className='list'>
							Enhances local manufacture and vendors to deliver custom goods as service and hence
							improve life
						</li>
						<li className='list'>
							Create 10x additinal workforce in each villages and cities across india,starting from
							bangalore as first hub
						</li>
						<li className='list'>
							Availability of easy custom made goods enable 20% reduction in the buy and throw
							culture, thus carbon footprint
						</li>
						<li className='list'>
							Attract more reluctant customers to online sector ,which further improves the tax
							generation for the governments.
						</li>
						<li className='list'>Market disruption in high end goods manufactures and distributors</li>
			
				</p>
        </div>
    </div>

  );
};

export default AboutUsPage;
