import './/Landing.css';
import { useState } from 'react';
import { FaUserAlt } from 'react-icons/fa';
import { IoMdArrowDropdown } from 'react-icons/io';
function Header() {
	const buttonStyle = {
		color: 'black',
		padding: '2px 3px',
		borderRadius: '3px',
		cursor: 'pointer',
		marginTop: '1px',
		margibBottom: '2px',

		fontSize: '15px',
	};
	const Dropdown = {
		marginTop: '10px',
		position: 'fixed',
		display: 'inline-block',
	};
	const [showButtons, setShowButtons] = useState(false);
	const toggleButtons = () => {
		setShowButtons(!showButtons);
	};

	const [menuOpen, setMenuOpen] = useState(false);

	return (
		<div className='navbar'>
			<nav>
				<div>
					<h4 className='title'>Ubrikk</h4>
				</div>

				<div
					className='menu'
					onClick={() => {
						setMenuOpen(!menuOpen);
					}}>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</div>

				<div className='nav'>
					<ul className={menuOpen ? 'open' : ''}>
						<li>
							<a href='/aboutus'>About Us</a>
						</li>
						<li>
							<a href='/products'>Products</a>
						</li>
						<li>
							<a href='/services'>Services</a>
						</li>
						<li>
							<a href='/vendorlist'>Vendors</a>
						</li>
					</ul>

					<>
						<div className='user'>
							<FaUserAlt size={26} />

							<IoMdArrowDropdown onClick={toggleButtons} style={Dropdown} />

							{showButtons && (
								<>
									<div className='signup-btn'>
										<a href='/signin'>
											<button style={buttonStyle}>Login</button>
										</a>
									</div>
									<div className='signup-btn'>
										<button style={buttonStyle}>Signup</button>
									</div>
								</>
							)}
						</div>
					</>
				</div>
			</nav>
		</div>
	);
}

export default Header;
